import $ from 'jquery';

$(window).on('scroll', function () {
    if ($(this).scrollTop() > 700) {
        $('.to-top').addClass('active');
    } else {
        $('.to-top').removeClass('active');
    }
});

$('.to-top').on('click', function () {
    $('body,html').animate({
        scrollTop: 0
    }, 600);
});