import $ from 'jquery';

$('[data-anchor]').on('click', function (e) {
  e.preventDefault();
  $('html, body').animate(
    {
      scrollTop: $($.attr(this, 'href')).offset().top,
    },
    300,
  );
});
