import $ from 'jquery';

$('[data-leaders]').on('change', function () {
  const $el = $(this);
  const id = $el.val();
  const $area = $(`#${$el.data('leaders')}`);
  const $items = $area.children();
  const $target = $(`#${id}`);

  if (!$target.hasClass('is-active')) {
    $items.removeClass('is-active');
    $target.addClass('is-active');
  }
});
