import $ from 'jquery';

const $steps = $('.card-create-steps-itm');
const $cardImages = $('[data-card-img]');

$(document)
  .on('changeStep', function (e) {
    const { step } = e.originalEvent.detail;
    const $nav = $('.card-create-nav .radio-buttons');

    if (step !== 1) {
      $nav.addClass('hide');
    } else {
      $nav.removeClass('hide');
    }

    $steps.each(function (idx) {
      const $el = $(this);

      idx < step ? $el.addClass('is-active') : $el.removeClass('is-active');
      idx + 1 === step
        ? $el.addClass('is-current')
        : $el.removeClass('is-current');
    });
  })
  .on('changeCard', (e) => {
    const { id, url } = e.originalEvent.detail;
    const $input = $('[data-card-style-input]');
    const $area = $('.card-create-text');

    $input.val(id);
    $area.attr('class', function (...props) {
      return props[1].replace(/style-.*/, '');
    }).addClass(`style-${id}`);

    $cardImages.each(function () {
      $(this).attr('src', url);
    });
  });

// Set card font size
function setFontSize() {
  const $cards = $('[data-card]');
  const [width, fontSize] = [576, 26];

  $cards.each(function () {
    const $el = $(this);
    const elWidth = $el.outerWidth();
    const fz =
      elWidth < width ? `${(elWidth * fontSize) / width}px` : `${fontSize}px`;

    $el.css('--font-size', fz);
  });
}

setFontSize();
$(document).ready(() => {
  setFontSize();
});
$(window).on('resize', setFontSize).on('zefir.render', setFontSize);

// Change card fort
$('[data-card-font-select]').on('change', function () {
  $('[data-card-textarea]')
    .removeClass(['card-font-1', 'card-font-2', 'card-font-3'])
    .addClass(`card-font-${$(this).val()}`);
});

// Comments
$('[data-comment-reveal]').on('zefir.open', function (...props) {
  $(this).find('[data-card-comment-target]').val(props[1].dataset.cardId);
});
