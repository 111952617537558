import $ from 'jquery';

$('[data-select]').each(function () {
  const $select = $(this);
  const $options = $select.children('option');
  const $jsSelect = $('<div class="js-select"></div>');
  const $btn = $('<button type="button" class="js-select-btn"></button>');
  const $jsOptionsWrap = $('<div class="js-select-cont"></div>').hide();
  const isSearch = $select.data('select-filter');
  const $search = $('<input type="text" class="js-select-search" />');

  $btn.text($select.children('option:selected').text());

  $options.each(function () {
    const $opt = $(this);

    const $b = $('<button />', {
      type: 'button',
      class: 'js-select-option',
      text: $opt.text(),
      val: $opt.val(),
    });

    $b.on('click', function () {
      $btn.text($opt.text());
      $select.val($opt.val()).trigger('change').trigger('click');
    });
    if (!$opt.attr('disabled')) {
      $jsOptionsWrap.append($b);
    }
  });

  if (isSearch) {
    $jsOptionsWrap.prepend($search);
    $search
      .on('click', function (e) {
        e.stopPropagation();
      })
      .on('keyup', function () {
        $jsOptionsWrap.find('.js-select-option').each(function () {
          const $b = $(this);
          let $regex = new RegExp($search.val(), 'i');
          if ($search.val() !== '') {
            !$b.text().match($regex) ? $b.hide() : $b.show();
          } else {
            $b.show();
          }
        });
      });
  }

  function setPosition() {
    if ($btn.hasClass('is-active')) {
      $jsOptionsWrap.css({
        top: $jsSelect.offset().top + $jsSelect.outerHeight() + 5,
        left: $jsSelect.offset().left,
        width: $jsSelect.outerWidth(),
      });
    }
  }

  $(window).on('resize', setPosition);
  $('*').add(window).on('scroll', setPosition);

  $jsSelect.append($btn);
  $('body').append($jsOptionsWrap);

  $select.hide().before($jsSelect);

  $(document).on('click', function () {
    $btn.removeClass('is-active');
    $jsOptionsWrap.slideUp(300);
    setPosition();
  });

  $btn.on('click', (e) => {
    e.stopPropagation();
    $btn.toggleClass('is-active');
    setPosition();
    $jsOptionsWrap.slideToggle(300, () => {
      if (isSearch && $btn.hasClass('is-active')) {
        $search.focus();
      }
    });
  });

  $select.on('update', () => {
    const $b = $jsSelect.find(`[value="${$select.val()}"]`);
    $b.trigger('click');
  });
});

// Product select
$('[data-product-select]').on('change', function () {
  const $select = $(this);
  const val = $select.val();
  const $card = $select.parents('.shop-card').eq(0);
  const $images = $card.find('[data-id]');
  const $activateImg = $card.find(`[data-id="${val}"]`);

  if ($activateImg.css('display') === 'none') {
    $images.hide();
    $activateImg.show();
  }
});

// Present select
// $('[data-choice]').on('change', function () {
//   const $value = $(this).val();
//   const $parent = $(this).closest('[data-choice-box]');
//   const $items = $parent.find('[data-choice-item]');
//   $items.removeClass('show');
//   $parent.find(`[` + $value + `]`).addClass('show');
// });

$('[data-choice]').each(function () {
  const $el = $(this);
  const $parent = $(this).closest('[data-choice-box]');
  const $items = $parent.find('[data-choice-item]');

  function update() {
    $items.each(function () {
      const $itm = $(this);
      const $inputs = $itm.find('input, select, textarea');

      if ($itm.data('choice-item') === $el.val()) {
        $itm.addClass('show');
        $inputs.attr('disabled', false);
      } else {
        $itm.removeClass('show');
        $inputs.attr('disabled', true);
      }
    });
  }

  update();
  $el.on('change', update);
});

$('[data-present-sizes]').on('change', function () {
  const $parent = $(this).closest('[data-choice-box]');
  $parent.find('[data-present-size] span').html($(this).val());
});
