import $ from 'jquery';

$('[data-accordion-item]').each(function () {
  const $ac = $(this);
  const $link = $ac.find('[data-accordion-link]');

  $link.on('click', function (ev) {
    ev.preventDefault();
    const $lnk = $(this);
    const $itm = $lnk.parents('[data-accordion-item]').first();
    const $cont = $itm.find('[data-accordion-content]');

    $cont.slideToggle(300);
    $itm.toggleClass('is-active');
  });
});
