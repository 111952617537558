import $ from 'jquery';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

$('.shop-card-fav').on('click', function (e) {
  e.preventDefault();
  const $btn = $(this);

  $.post(
    ajx.url,
    {
      action: ajx.name + '_items_favorite',
      data: {
        nonce: ajx.nonce,
        user: ajx.user,
        id: parseInt($btn.attr('data-id')),
        active: $btn.hasClass('is-active'),
      },
    },
    function (res) {
      if (parseInt(res) > 0) {
        $btn.hasClass('is-active')
          ? $btn.removeClass('is-active')
          : $btn.addClass('is-active');
      }
    },
  );
});

// Shop order example
$('.product-rev-form').on('submit', function (ev) {
  const $form = $(this).addClass('sending');
  ev.preventDefault();

  function done() {
    $form.removeClass('sending');
    $form.parents('.product-rev').eq(0).trigger('done');
  }

  $.post(
    $form.attr('action'),
    {
      action: $form.find('[name="action"]').eq(0).val(),
      data: $form.serializeArray(),
    },
    function (res) {
      if (parseInt(res) > 0) {
        setTimeout(done, 100);
      }
    },
  );
});

$('.shop-card-info').on('click', function () {
  const $parent = $(this).parent('.shop-card-head');
  const $infoBox = $parent.find('.shop-card-info-box');
  $infoBox.toggleClass('is-active');
  $(this).toggleClass('is-active');
});

const lightbox = new PhotoSwipeLightbox({
  bgOpacity: 0.9,
  gallery: '[data-magnifier]',
  children: '[data-magnifier-link]',
  showHideAnimationType: 'zoom',
  arrowPrev: false,
  arrowNext: false,
  counter: false,
  pswpModule: PhotoSwipe,
});

lightbox.init();
