import $ from 'jquery';

// Category drop down
$('[data-cat-dd]').each(function () {
  const $el = $(this);
  const [$btn, $cont] = [
    $el.find('[data-cat-dd-btn]'),
    $el.find('[data-cat-dd-cont]'),
  ];

  function setState() {
    if ($(window).outerWidth() < 1024) {
      $btn.removeClass('is-active');
      $cont.hide();
    } else {
      $btn.addClass('is-active');
      $cont.show();
    }
  }

  setState();
  $(window).on('resize', setState);

  function toggle() {
    if (!$btn.hasClass('is-active')) {
      $btn.addClass('is-active');
      $cont.slideDown(300);
    } else {
      $btn.removeClass('is-active');
      $cont.slideUp(300);
    }
  }

  $btn.on('click', toggle);
});
