import $ from 'jquery';

window.$ = $;
window.jQuery = $;

// Open trigger
$(document).on('click', '[data-open]', function () {
  const $target = $($(this).data('open'));
  $target.trigger('zefir.open', [this]);
});

// Toggle trigger
$(document).on('click', '[data-toggle]', function () {
  const $target = $($(this).data('toggle'));
  $target.trigger('zefir.toggle', [this]);
});

// Close trigger
$(document).on('click', '[data-close]', function () {
  const $target = $($(this).data('close'));

  if ($target.length) {
    $target.trigger('zefir.close', [this]);
  } else {
    $(document).trigger('zefir.close', [this]);
  }
});

document.addEventListener("DOMContentLoaded", ()=>{
  if (window.matchMedia("(max-width: 768px)").matches) {
    if(document.querySelector(".header-user-avatar")){
      document.querySelector(".header-user-avatar").addEventListener('click', (e)=>{
        e.preventDefault();
      })
    }
  }
})


// animation
$(".dash-hero").each(function () {
  const $section = $(this);

  const anim = (fromTop) => {
    const windowHeight = $(window).outerHeight();
    const percent = (fromTop * 100) / windowHeight;
    let opacity = (100 - percent * 4.5) / 100;

    if (window.innerWidth >= 1024){
      opacity = (100 - percent * 2) / 100;
      $section
          .css("top", `${fromTop / 4}px`)
          .css("opacity", opacity < 0 ? 0 : opacity);
    }else {
      $section
          .css("top", `${fromTop / 8}px`)
          .css("opacity", opacity < 0 ? 0 : opacity);
    }

  };

  $(document).ready(() => {
    anim($(window).scrollTop());
  });
  $(window).scroll(() => {
    anim($(window).scrollTop());
  });
});

$("main").each(function () {
  const $el = $(this);
  const $sections = $el.find("[data-opacity-animate]");

  const setColor = (fromTop) => {
    const windowHeight = $(window).outerHeight();

    $sections.each(function (i) {
      const $section = $(this);
      const positionTop = $section.position().top;
      const topSpace = parseInt($section.css("padding-top")) + 86;

      if (fromTop + windowHeight > positionTop + topSpace) {
        $section.addClass("active-section");
      } else {
        $section.removeClass("active-section");
      }
    });
  };

  $(document).ready(() => {
    setColor($(window).scrollTop());
  });
  $(window).scroll(() => {
    setColor($(window).scrollTop());
  });
});

import './modules/off-canvas';
import './modules/accordion';
import './modules/tabs';
import './modules/forms';
import './modules/drop-down';
import './modules/select';
import './modules/select_leaders';
import './modules/reveal';
import './modules/cards';
import './modules/anchors';
import './modules/shop';
import './modules/to-top';
