import $ from 'jquery';

function reveal() {
  $('[data-reveal]').each(function () {
    const $reveal = $(this);

    const isWrapped = $reveal.parent().hasClass('reveal-wrap');

    if (!isWrapped) {
      let $wrap = '';
      if($reveal.hasClass('comment-reveal') || $reveal.hasClass('zks-card-text-rev')){
        $wrap = $('<div class="reveal-wrap extra-wrap"></div>')
            .css('display', 'flex')
            .hide();
      }else {
        $wrap = $('<div class="reveal-wrap"></div>')
            .css('display', 'flex')
            .hide();
      }

      $('body').append($wrap.append($reveal));
    }
  });
}

$(document).on('zefir.open', '[data-reveal]', function () {
  const $el = $(this);
  const $wrap = $el.parent();

  $wrap.fadeIn(300);
});

$(document).on('zefir.close', '[data-reveal]', function () {
  $(this).each(function () {
    $(this).parent().fadeOut(300);
  });
});

$(document).on('zefir.close', function () {
  $('[data-reveal]').each(function () {
    $(this).parent().fadeOut(300);
  });
});

reveal();
$(document).ready(() => reveal());
$(window).on('zefir.render', reveal);

// Product reveal
$('.product-rev')
  .on('done', function () {
    const $rev = $(this);
    const [$cont, $thx] = [
      $rev.find('.product-rev-cont'),
      $rev.find('.product-rev-thx'),
    ];

    $cont.hide();
    $thx.css('display', 'flex');
  })
  .on('zefir.open', function (...props) {
    const $rev = $(this);
    const $button = $(props[1]);
    const $card = $button.parents('.shop-card').eq(0);
    const $select = $card.find('[data-product-select]');

    if ($select.length) {
      const $revSelect = $rev.find('[data-product-select]');
      $revSelect.val($select.val()).trigger('change').trigger('update');

      $select.val();
    }
  });

// Sizes popup
const $openPresPopupBtn = $('.present-form-popup [data-show-popup]');
const $closePresPopupBtn = $('.present-form-popup [data-close-popup]');

$openPresPopupBtn.on('click', function () {
  const $parent = $(this).closest('.present-form-popup');
  const $items = $parent.find('.present-form-popup-window');
  $items.addClass('open');
});
$closePresPopupBtn.on('click', function () {
  const $parent = $(this).closest('.present-form-popup');
  const $items = $parent.find('.present-form-popup-window');
  $items.removeClass('open');
});

// notifications popup
const $openGetPopupBtn = $('[data-show-get-popup]');
const $closeGetPopupBtn = $('.get-present-popup [data-close-popup]');

$openGetPopupBtn.on('click', function () {
  const $parent = $(this).closest('[data-has-popup]');
  const $info = $parent.find('[data-for-popup]').html();
  const $popup = $parent.find('.get-present-popup');
  const $popupInfo = $popup.find('.get-present-popup-info');
  $popupInfo.html($info);
  $popup.addClass('open');
});

$closeGetPopupBtn.on('click', function () {
  const $parent = $(this).closest('[data-has-popup]');
  const $popup = $parent.find('.get-present-popup');
  $popup.removeClass('open');
});

const $closeThankPopupBtn = $('.thank-present-popup [data-close-popup]');

$closeThankPopupBtn.on('click', function () {
  const $popup = $(this).closest('.thank-present-popup');
  $popup.removeClass('open');
});

