import $ from 'jquery';
import 'jquery-validation';

class Loader {
  constructor() {
    this.el = $('.ajax-loader');
  }

  show() {
    this.el.addClass('is-active');
  }

  hide() {
    this.el.removeClass('is-active');
  }
}

const loader = new Loader();

$.validator.setDefaults({
  ignore: [],
  errorPlacement: function (error, element) {
    error.appendTo(element.parent());
  },
  errorElement: 'span',
});

$.validator.addMethod(
  'tel',
  function (value) {
    return /([0-9]{10})|(\([0-9]{3}\)\s+[0-9]{3}\-[0-9]{4})/.test(value);
  },
  'Invalid phone number',
);

const $validationForms = $('[data-validate]');

$validationForms.each(function( index ) {
  $(this).validate({
    errorElement: 'span',
  });
});

$validationForms.on('sending', function () {
  this.classList.add('sending');
});

$validationForms.on('done', function (...attrs) {
  this.classList.remove('sending');
  attrs[0].target.reset();
  formPopUp(attrs[1]);
});

function formPopUp({
  title = (typeof i18n.s1 == 'undefined') ? 'Thanks!' : i18n.s1,
  text = (typeof i18n.s2 == 'undefined') ? 'Your message has been sent' : i18n.s2,
} = {}) {
  const $popup = $('<div/>', { class: 'cf-reveal' });
  const $window = $(
    `<div class="cf-reveal-window"><div class="cf-reveal-title">${title}</div><div class="cf-reveal-text">${text}</div></div>`,
  );
  const $close = $(
    '<button class="cf-reveal-close"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.6569 19.8926C19.2663 20.2831 18.6332 20.2831 18.2426 19.8926L14 15.6499L9.75736 19.8926C9.36683 20.2831 8.73367 20.2831 8.34315 19.8926L8.10744 19.6569C7.71692 19.2663 7.71692 18.6332 8.10744 18.2426L12.3501 14L8.10744 9.75736C7.71692 9.36684 7.71692 8.73367 8.10744 8.34315L8.34315 8.10745C8.73367 7.71692 9.36683 7.71692 9.75736 8.10745L14 12.3501L18.2426 8.10745C18.6332 7.71692 19.2663 7.71692 19.6569 8.10745L19.8926 8.34315C20.2831 8.73367 20.2831 9.36684 19.8926 9.75736L15.6499 14L19.8926 18.2426C20.2831 18.6332 20.2831 19.2663 19.8926 19.6569L19.6569 19.8926Z" fill="#141416"/></svg></button>',
  );

  $popup.append($window.append($close));

  $popup.css('display', 'flex').hide();

  $('body').append($popup.fadeIn());

  $close.on('click', function () {
    $popup.fadeOut(200, () => {
      $popup.remove();
    });
  });
}

// Sending form
$('#cf').on('submit', function (e) {
  e.preventDefault();
  const $form = $(e.target);
  const data = $form.serializeArray();

  if ($form.valid()) {
    $form.trigger('sending');
    console.log(data);

    // Ajax function
    setTimeout(() => {
      $form.trigger('done', {
        title: (typeof i18n.s1 == 'undefined') ? 'Thanks!' : i18n.s1,
        text: (typeof i18n.s2 == 'undefined') ? 'Your message has been sent' : i18n.s2,
      });
    }, 2000);
  }
});

// Card comment form
$('#card-comment').on('submit', function (e) {
  e.preventDefault();
  const $form = $(this);

  if ($form.valid()) {
    $form.addClass('sending');

    function done() {
      $form.removeClass('sending')[0].reset();
      $(document).trigger('zefir.close');
    }

    // Your ajax
    $.post(
      $form.attr('action'),
      {
        action: $form.find('[name="action"]').eq(0).val(),
        data: $form.serializeArray(),
      },
      function (res) {
        if (parseInt(res) > 0) {
          setTimeout(done, 200);
          window.location.reload();
        }
      },
    );
  }
});

// Card form
$('#card-form').on('submit', function (e) {
  e.preventDefault();
  const $form = $(this);

  if ($form.valid()) {
    $form.addClass('sending');
    loader.show();

    function done() {
      loader.hide();
      $form.removeClass('sending')[0].reset();
      $(document).trigger('zefir.close');
      $('#card-send-reveal').trigger('zefir.open');
    }

    // Your ajax
    $.post(
      $form.attr('action'),
      {
        action: $form.find('[name="action"]').eq(0).val(),
        data: $form.serializeArray(),
      },
      function (res) {
        if (parseInt(res) > 0) {
          setTimeout(done, 200);
        }
      },
    );

    $form.removeClass('sending');
  }
});

// Gift form done
function giftDone($form) {
  $form.removeClass('sending')[0].reset();
  $(document).trigger('zefir.close');
  const $parent = $form.closest('[data-has-popup]');
  const $popup = $parent.find('.thank-present-popup');
  $popup.addClass('open');
  $parent.find('.get-present-popup').removeClass('open');
}

// Gift form
$('.gift-request-form').on('submit', function (e) {
  e.preventDefault();
  const $form = $(this);

  if ($form.valid()) {
    $form.addClass('sending');

    // Your ajax
    $.post(
      $form.attr('action'),
      {
        action: $form.find('[name="action"]').eq(0).val(),
        data: $form.serializeArray(),
      },
      function (res) {
        if (parseInt(res) > 0) {
          setTimeout(() => {
            giftDone($form);
          }, 200);
        }
      },
    );
  }
});

// Checkbox
$('[type="checkbox"] + label').on('keypress', function (ev) {
  ev.preventDefault();
  const keys = [' ', 'Enter'];

  if (keys.includes(ev.key)) {
    $(this).prev('[type="checkbox"]').click();
  }
});

// Card Loadmore
$('.crd-area-btn').on('submit', function (e) {
  e.preventDefault();
  const $form = $(this);
  const btn = $form.find('[type="submit"]');
  const step = $form.find('[name="step"]');

  btn.prop('disabled', true);

  $.post(
    $form.attr('action'),
    {
      action: $form.find('[name="action"]').eq(0).val(),
      data: $form.serializeArray(),
    },
    function (res) {
      const resObj = JSON.parse(res);
      const cont = $form.prev('.zks-cards-grid');
      if (resObj.items.length) {
        for (let i = 0; i < resObj.items.length; i++) {
          cont.append($(resObj.items[i]));
          $(window).trigger('zefir.render');
        }
      }
      if (resObj.items.length < parseInt($form.find('[name="num"]').val())) {
        btn.remove();
      }
    },
  ).always(function () {
    btn.prop('disabled', false);
    step.val(parseInt(step.val()) + 1);
  });
});

// Transactions Loadmore

$('#transactions-loadmore').on('submit', function (e) {
  e.preventDefault();
  const $form = $(this);
  const btn = $form.find('[type="submit"]');

  btn.prop('disabled', true);

  $.post(
    $form.attr('action'),
    {
      action: $form.find('[name="action"]').eq(0).val(),
      data: $form.serializeArray(),
    },
    function (res) {
      let resObj = JSON.parse(res);
      let cont = $form.prev('.orders-page-list');
      if (resObj.items.length) {
        for (let i = 0; i < resObj.items.length; i++) {
          cont.append($(resObj.items[i]));
        }
      }
      if (resObj.items.length < parseInt($form.find('[name="num"]').val())) {
        btn.remove();
      }
    },
  ).always(function () {
    btn.prop('disabled', false);
  });
});

// Notification pagination
$('.pag-ntcs').on('click', function (e) {
  e.preventDefault();
  const $btn = $(this);
  const $form = $('#notifications-pagination');
  const $step = parseInt($btn.attr('data-step'));

  if ($step !== parseInt($form.find('[name="step"]').val())) {
    $form.find('[name="step"]').val($step);

    $.post(
      $form.attr('action'),
      {
        action: $form.find('[name="action"]').eq(0).val(),
        data: $form.serializeArray(),
      },
      function (res) {
        let resObj = JSON.parse(res);
        let cont = $form.prev('.notifications-page-list');
        if (resObj.items.length) {
          cont.html('');
          for (let i = 0; i < resObj.items.length; i++) {
            cont.append($(resObj.items[i]));
          }
        }
      },
    ).always(function () {
      $('.pag-ntcs').removeClass('is-active');
      $('.pag-ntcs[data-step="' + $step + '"]').addClass('is-active');

      let $prev = $btn.parent('.pag-list').find('.pag-ntcs-prev');
      let $next = $btn.parent('.pag-list').find('.pag-ntcs-next');

      if ($step > parseInt($prev.attr('data-min'))) {
        $prev.attr('data-step', $step - 1);
      } else if ($step == parseInt($prev.attr('data-min'))) {
        $prev.attr('data-step', $step);
      }

      if ($step < parseInt($next.attr('data-max'))) {
        $next.attr('data-step', $step + 1);
      } else if ($step == parseInt($next.attr('data-max'))) {
        $next.attr('data-step', $step);
      }
    });
  }
});


// Support form
$('#support-reveal-form').on('submit', function (e) {
  e.preventDefault();
  const $form = $(e.target);
  const btn = $form.find('[type="submit"]');

  btn.prop('disabled', true);

  if ($form.valid()) {
    $form.trigger('sending');
	
	// Your ajax
    $.post(
      $form.attr('action'),
      {
        action: $form.find('[name="action"]').eq(0).val(),
        data: $form.serializeArray(),
      },
      function (res) {
        if (parseInt(res) > 0) {
			setTimeout(() => {
			  $form.trigger('done', {
				title:  (typeof i18n.s1 == 'undefined') ? 'Thanks!' : i18n.s1,
				text: (typeof i18n.s2 == 'undefined') ? 'Your message has been sent' : i18n.s2,
			  });
			  $form.parents('.reveal-wrap').css('display', 'none');
			}, 200);
        }
      },
    ).always(function () {
		btn.prop('disabled', false);
	});
  }
});
