import $ from 'jquery';

// Check height for card text
function checkTextHeight() {
  setTimeout(function () {
    const $cards = $('.is-active .zks-card-txt');

    $cards.each(function () {
      const $el = $(this);
      const $elText = $(this).find('.zks-card-txt-val');

      const elHeight = $elText.height();
      const elScrollHeight = $elText.prop('scrollHeight');

      if (elHeight < elScrollHeight - 1) {
        $el.addClass('more-text');
      } else {
        $el.removeClass('more-text');
      }
    });
  }, 200);
}

$(document).ready(function () {
  checkTextHeight();
});

$(window).on('zefir.render', checkTextHeight);

$('[data-tabs]').each(function () {
  const $el = $(this);
  const $buttons = $el.find('a');
  const $cont = $(`[data-tabs-content="${$el.data('tabs')}"]`);
  const $panels = $cont.children();

  $buttons.on('click', function (ev) {
    ev.preventDefault();
    const $btn = $(this);
    const $panel = $panels.filter($btn.attr('href'));

    if (!$btn.hasClass('is-active')) {
      $buttons.removeClass('is-active');
      $btn.addClass('is-active');
    }

    if (!$panel.hasClass('is-active')) {
      $panels.removeClass('is-active').hide();
      $panel.fadeIn(300, () => {}).addClass('is-active');
      $(document).trigger('zefir.render');
      checkTextHeight();
    }
  });
});

$('[data-tabs-radio]').each(function () {
  const $el = $(this);
  const $buttons = $el.find('input');
  const $cont = $(`[data-tabs-content="${$el.data('tabs-radio')}"]`);
  const $panels = $cont.children();

  $buttons.on('click', function (ev) {
    const $btn = $(this);
    const $panel = $('#' + $btn.val());

    if (!$panel.hasClass('is-active')) {
      $panels.removeClass('is-active').hide();
      $panel.fadeIn(300).addClass('is-active');
    }
  });
});
